import axios, { AxiosError } from 'axios';
import { UserState } from '../contexts/UserContext';

const baseURL = 'http://localhost:5001';
type NavihomeServerError = { statusCode: number, message: string, error: string };

const parseError = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    const serverError = error as AxiosError<NavihomeServerError>;
    if (serverError && serverError.response) {
      return serverError.response.data.message;
    }
  }
}

export interface Listing {
  mlsId: number,
  property : {
    area: number,
    bathsFull: number,
    bathsHalf: number,
    bedrooms: number,
    yearBuilt: number,
    type: string
  },
  address: {
    postalCode: string,
    streetNumber: number,
    streetName: string,
    city: string,
    state: string,
  },
  photos: string[]
  listDate: string,
  listPrice: number,
  mls: {
    daysOnMarket: number
  }
  remarks: string
}

export interface SavedSearch {
  savedSearchId?: number,
  city: string,
  state: string,
  query?: string | null,
  minPrice?: number | null,
  maxPrice?: number | null,
  beds?: number | null,
  baths?: number | null,
  halfBaths?: number | null;
}

interface Buyer {
  buyerId: number,
  name: string,
  email: string,
  phone: string,
  imageUrl: string
}

export interface SaleAgent {
  saleAgentId: number,
  name: string,
  email: string,
  phone: string,
  company: string,
  imageUrl: string
}

export interface BuyerInvitation {
  saleAgentId: number
}

const getConfigWithToken = (token: string) => {
  return {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
}

// LOG IN ........................................................................................................................

export const logInBuyerService = async (email: string, password: string) => {
  try {
    const response: { data: UserState['buyer'] } = await axios.post(`${baseURL}/buyers/login`, 
      { email, password });
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const logInSaleAgentService = async (email: string, password: string) => {
  try {
    const response: { data: UserState['saleAgent'] } = await axios.post(`${baseURL}/sale-agents/login`, 
      { email, password });
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

// FORGOT PASSWORD ........................................................................................................................

export const sendForgotPasswordForBuyerService = async (email: string) => {
  try {
    const response  = await axios.post(`${baseURL}/buyers/forgot-password`, 
      { email });
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const sendForgotPasswordForSaleAgentService = async (email: string) => {
  try {
    const response  = await axios.post(`${baseURL}/sale-agents/forgot-password`, 
      { email });
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

// REGISTER ........................................................................................................................

export const registerBuyerService = async (email: string, password: string, phone: string, name: string) => {
  try {
    const response: { data: UserState['buyer'] } = await axios.post(`${baseURL}/buyers`,
      { email, password, name, phone });
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const registerSaleAgentService = async (email: string, password: string, phone: string, name: string, company: string, mlsId: string) => {
  try {
    const response: { data: UserState['saleAgent'] } = await axios.post(`${baseURL}/sale-agents`,
      { email, password, name, phone, company, mlsId });
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

// PROPERTIES ....................................................................................................................

export const getPropertiesService = async (query: string) => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties?${query}`);
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const getPropertiesOpenHousesService = async () => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties/open-houses`);
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const getPropertiesNewListingsService = async () => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties/new-listings`);
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const getPropertiesAffordableService = async () => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties/affordable`);
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const getPropertiesLuxuryService = async () => {
  try {
    const response = await axios.get<Listing[]>(`${baseURL}/properties/luxury`);
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const getPropertyDetailsService = async (propertyId: number) => {
  try {
    const response = await axios.get<Listing>(`${baseURL}/properties/${propertyId}`);
    return response.data;
  } catch(error) {
    throw new Error(parseError(error));
  }
}

// FAVORITE PROPERTIES .......................................................................................

export const addPropertyToFavoritesForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/properties/${propertyId}/add-favorites/buyer/${buyerId}`, {},
      getConfigWithToken(token));

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const removePropertyFromFavoritesForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.patch(`${baseURL}/properties/${propertyId}/remove-favorites/buyer/${buyerId}`, {},
      getConfigWithToken(token));

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const checkIfPropertyIsFavoriteForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.get<{propertyId: number, buyerId: number}, { data: { isFavorite: boolean } }>(`${baseURL}/properties/${propertyId}/favorite/buyer/${buyerId}`, 
      getConfigWithToken(token));

    return response.data.isFavorite;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const getFavoritePropertiesForBuyerService = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get<{buyerId: number}, { data:  { propertyId: number }[]}>(`${baseURL}/properties/favorites/buyer/${buyerId}`, 
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

// REQUESTED PROPERTIES .......................................................................................

export const addPropertyToRequestedForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/properties/${propertyId}/add-requested/buyer/${buyerId}`, {},
      getConfigWithToken(token));

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const removePropertyFromRequestedForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.patch(`${baseURL}/properties/${propertyId}/remove-requested/buyer/${buyerId}`, {},
      getConfigWithToken(token));

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const checkIfPropertyIsRequestedForBuyerService = async (propertyId: number, buyerId: number, token: string) => {
  try {
    const response = await axios.get<{propertyId: number, buyerId: number}, { data: { isRequested: boolean } }>(`${baseURL}/properties/${propertyId}/requested/buyer/${buyerId}`, 
      getConfigWithToken(token));

    return response.data.isRequested;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const getRequestedPropertiesForBuyerService = async (buyerId: number, token: string) => {
  try {
    const response = await axios.get<{buyerId: number}, { data:  { propertyId: number }[]}>(`${baseURL}/properties/requested/buyer/${buyerId}`, 
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

// INVITATIONS ........................................................................................................

export const getLinkedBuyersService = async (token: string) => {
  try {
    const response = await axios.get<{}, { data: Buyer[] }>(`${baseURL}/buyers/linked`, 
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const inviteBuyerToAgentService = async (name: string, email: string, message: string, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/buyers/invite`, 
      { name, email, message },
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}
  
export const inviteAgentService = async (name: string, email: string, message: string, token: string) => {
  try {
    const response = await axios.post(`${baseURL}/sale-agents/invite`, 
      { name, email, message },
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const unlinkBuyerFromSaleAgentService = async (token: string, buyerId: number) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/${buyerId}/unlink`, 
      {},
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const acceptBuyerInvitationService = async (token: string, saleAgentId: number) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/sale-agent/${saleAgentId}/accept-invitation`, 
      {},
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const rejectBuyerInvitationService = async (token: string, saleAgentId: number) => {
  try {
    const response = await axios.patch(`${baseURL}/buyers/sale-agent/${saleAgentId}/reject-invitation`, 
      {},
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

// SAVED SEARCHES ........................................................................................................

export const saveSearchService = async (token: string, search: SavedSearch ) => {
  try {
    const response = await axios.post(`${baseURL}/saved-searches`, 
      search,
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const getSavedSearchesService = async (token: string, buyerId: number) => {
  try {
    const response = await axios.get<{}, { data: SavedSearch[] }>(`${baseURL}/saved-searches/buyer/${buyerId}`, 
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

export const deleteSavedSearchService = async (token: string, savedSearchId: number) => {
  try {
    const response = await axios.delete(`${baseURL}/saved-searches/${savedSearchId}`, 
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

// NOTIFICATIONS ........................................................................................................

export const getBuyerInvitationsService = async (token: string) => {
  try {
    const response = await axios.get<{}, { data: BuyerInvitation[] }>(`${baseURL}/buyers/invitations`, 
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}

// SALE AGENTS ........................................................................................................

export const getSaleAgentInfoService = async (token: string, saleAgentId: number) => {
  try {
    const response = await axios.get<{}, { data: SaleAgent }>(`${baseURL}/sale-agents/${saleAgentId}`, 
      getConfigWithToken(token));
    return response.data;

  } catch(error) {
    throw new Error(parseError(error));
  }
}