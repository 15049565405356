import React, { useReducer } from 'react';
import { SavedSearch } from '../services/NavihomeService';

enum PropertyFiltersActionType {
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  SET_CITY = 'SET_CITY',
  SET_STATE = 'SET_STATE',
  SET_QUERY = 'SET_QUERY',
  SET_MIN_PRICE = 'SET_MIN_PRICE',
  SET_MAX_PRICE = 'SET_MAX_PRICE',
  SET_BEDS = 'SET_BEDS',
  SET_BATHS = 'SET_BATHS',
  SET_HALF_BATHS = 'SET_HALF_BATHS',
  SET_MIN_SQUARE_FOOTAGE = 'SET_MIN_SQUARE_FOOTAGE',
  SET_MAX_SQUARE_FOOTAGE = 'SET_MAX_SQUARE_FOOTAGE',
  SET_MIN_LOT_SIZE = 'SET_MIN_LOT_SIZE',
  SET_MAX_LOT_SIZE = 'SET_MAX_LOT_SIZE',
  SET_HEATING_COOLING = 'SET_HEATING_COOLING',
  SET_MIN_YEAR_BUILT = 'SET_MIN_YEAR_BUILT',
  SET_MAX_YEAR_BUILT = 'SET_MAX_YEAR_BUILT',
  SET_MIN_YEAR_CONVERTED = 'SET_MIN_YEAR_CONVERTED',
  SET_MAX_YEAR_CONVERTED = 'SET_MAX_YEAR_CONVERTED',
  SET_PARKING_SPACES = 'SET_PARKING_SPACES',
  SET_GARAGES = 'SET_GARAGES',
  SET_OFF_STREET_PARKING = 'SET_OFF_STREET_PARKING'
}

type PropertyFiltersAction =
  | { type: PropertyFiltersActionType.CLEAR_FILTERS }
  | { type: PropertyFiltersActionType.SET_CITY, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_STATE, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_QUERY, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_MIN_PRICE, payload: { value: number } }
  | { type: PropertyFiltersActionType.SET_MAX_PRICE, payload: { value: number } }
  | { type: PropertyFiltersActionType.SET_BEDS, payload: { value: number } }
  | { type: PropertyFiltersActionType.SET_BATHS, payload: { value: number } }
  | { type: PropertyFiltersActionType.SET_HALF_BATHS, payload: { value: number } }
  | { type: PropertyFiltersActionType.SET_MIN_SQUARE_FOOTAGE, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_MAX_SQUARE_FOOTAGE, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_MIN_LOT_SIZE, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_MAX_LOT_SIZE, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_HEATING_COOLING, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_MIN_YEAR_BUILT, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_MAX_YEAR_BUILT, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_MIN_YEAR_CONVERTED, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_MAX_YEAR_CONVERTED, payload: { value: string } }
  | { type: PropertyFiltersActionType.SET_PARKING_SPACES, payload: { value: number } }
  | { type: PropertyFiltersActionType.SET_GARAGES, payload: { value: number } }
  | { type: PropertyFiltersActionType.SET_OFF_STREET_PARKING, payload: { value: number } }


interface PropertyFiltersState {
  city: string,
  state: string,
  query: string,
  minPrice: number,
  maxPrice: number,
  beds: number,
  baths: number,
  halfBaths: number,
  minSquareFootage: string,
  maxSquareFootage: string,
  minLotSize: string,
  maxLotSize: string,
  heatingCooling: string,
  minYearBuilt: string,
  maxYearBuilt: string,
  minYearConverted: string,
  maxYearConverted: string,
  parkingSpaces: number,
  garages: number,
  offStreetParking: number
}

type PropertyFiltersContextType = {
  propertyFiltersState: PropertyFiltersState;
  getFiltersQueryString: () => string;
  getFiltersForSavedSearch: () => SavedSearch;
  clearFilters: () => void;
  setCity: (value: string) => void;
  setState: (value: string) => void;
  setQuery: (value: string) => void;
  setMinPrice: (value: number) => void;
  setMaxPrice: (value: number) => void;
  setBeds: (value: number) => void;
  setBaths: (value: number) => void;
  setHalfBaths: (value: number) => void;
  setMinSquareFootage: (value: string) => void;
  setMaxSquareFootage: (value: string) => void;
  setMinLotSize: (value: string) => void;
  setMaxLotSize: (value: string) => void;
  setHeatingCooling: (value: string) => void;
  setMinYearBuilt: (value: string) => void;
  setMaxYearBuilt: (value: string) => void;
  setMinYearConverted: (value: string) => void;
  setMaxYearConverted: (value: string) => void;
  setParkingSpaces: (value: number) => void;
  setGarages: (value: number) => void;
  setOffStreetParking: (value: number) => void;
}

const initialState: PropertyFiltersState = {
  city: 'Houston',
  state: 'Texas',
  query: '',
  minPrice: 0,
  maxPrice: 0,
  beds: 1,
  baths: 1,
  halfBaths: 0,
  minSquareFootage: '',
  maxSquareFootage: '',
  minLotSize: '',
  maxLotSize: '',
  heatingCooling: '',
  minYearBuilt: '',
  maxYearBuilt: '',
  minYearConverted: '',
  maxYearConverted: '',
  parkingSpaces: 0,
  garages: 0,
  offStreetParking: 0
}

const PropertyFiltersContext = React.createContext<PropertyFiltersContextType | undefined>(undefined);

const reducer = (state: PropertyFiltersState, action: PropertyFiltersAction): PropertyFiltersState => {
  switch (action.type) {
    case PropertyFiltersActionType.CLEAR_FILTERS:
      return initialState;
    case PropertyFiltersActionType.SET_CITY:
      return { ...state, city: action.payload.value };
    case PropertyFiltersActionType.SET_STATE:
      return { ...state, state: action.payload.value };
    case PropertyFiltersActionType.SET_QUERY:
      return { ...state, query: action.payload.value };
    case PropertyFiltersActionType.SET_MIN_PRICE:
      return { ...state, minPrice: action.payload.value };
    case PropertyFiltersActionType.SET_MAX_PRICE:
      return { ...state, maxPrice: action.payload.value };
    case PropertyFiltersActionType.SET_BEDS:
      return { ...state, beds: action.payload.value };
    case PropertyFiltersActionType.SET_BATHS:
      return { ...state, baths: action.payload.value };
    case PropertyFiltersActionType.SET_HALF_BATHS:
      return { ...state, halfBaths: action.payload.value };
    case PropertyFiltersActionType.SET_MIN_SQUARE_FOOTAGE:
      return { ...state, minSquareFootage: action.payload.value };
    case PropertyFiltersActionType.SET_MAX_SQUARE_FOOTAGE:
      return { ...state, maxSquareFootage: action.payload.value };
    case PropertyFiltersActionType.SET_MIN_LOT_SIZE:
      return { ...state, minLotSize: action.payload.value };
    case PropertyFiltersActionType.SET_MAX_LOT_SIZE:
      return { ...state, maxLotSize: action.payload.value };
    case PropertyFiltersActionType.SET_HEATING_COOLING:
      return { ...state, heatingCooling: action.payload.value };
    case PropertyFiltersActionType.SET_MIN_YEAR_BUILT:
      return { ...state, minYearBuilt: action.payload.value };
    case PropertyFiltersActionType.SET_MAX_YEAR_BUILT:
      return { ...state, maxYearBuilt: action.payload.value };
    case PropertyFiltersActionType.SET_MIN_YEAR_CONVERTED:
      return { ...state, minYearConverted: action.payload.value };
    case PropertyFiltersActionType.SET_MAX_YEAR_CONVERTED:
      return { ...state, maxYearConverted: action.payload.value };
    case PropertyFiltersActionType.SET_PARKING_SPACES:
      return { ...state, parkingSpaces: action.payload.value };
    case PropertyFiltersActionType.SET_GARAGES:
      return { ...state, garages: action.payload.value };
    case PropertyFiltersActionType.SET_OFF_STREET_PARKING:
      return { ...state, offStreetParking: action.payload.value };
    default:
      return state;
  }
}

export const PropertyFiltersProvider: React.FC = ({ children }) => {
  const [propertyFiltersState, dispatch] = useReducer(reducer, initialState);

  const getFiltersQueryString = () => {
    const { city, state, query, minPrice, maxPrice, beds, baths, halfBaths } = propertyFiltersState;
    var queryString = `city=${encodeURI(city)}&state=${encodeURI(state)}`;

    if (query !== initialState.query) {
      queryString = queryString.concat(`&query=${encodeURI(query)}`);
    }

    if (minPrice !== initialState.minPrice) {
      queryString = queryString.concat(`&min_price=${minPrice}`);
    }

    if (maxPrice !== initialState.maxPrice) {
      queryString = queryString.concat(`&max_price=${maxPrice}`);
    }

    if (beds !== initialState.beds) {
      queryString = queryString.concat(`&beds=${beds}`);
    }

    if (baths !== initialState.baths) {
      queryString = queryString.concat(`&baths=${baths}`);
    }

    if (halfBaths !== initialState.halfBaths) {
      queryString = queryString.concat(`&half_baths=${halfBaths}`);
    }

    return queryString;
  }

  const getFiltersForSavedSearch = () => {
    const { city, state, query, minPrice, maxPrice, beds, baths, halfBaths } = propertyFiltersState;

    var savedSearch: SavedSearch = { city, state};
    
    if (query !== initialState.query) {
      savedSearch.query = query;
    }

    if (minPrice !== initialState.minPrice) {
      savedSearch.minPrice = minPrice;
    }

    if (maxPrice !== initialState.maxPrice) {
      savedSearch.maxPrice = maxPrice;
    }

    if (beds !== initialState.beds) {
      savedSearch.beds = beds;
    }

    if (baths !== initialState.baths) {
      savedSearch.baths = baths;
    }

    if (halfBaths !== initialState.halfBaths) {
      savedSearch.halfBaths = halfBaths;
    }
    return savedSearch;
  }

  const clearFilters = () => {
    dispatch({ type: PropertyFiltersActionType.CLEAR_FILTERS })
  }

  const setCity = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_CITY, payload: { value } })
  }

  const setState = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_STATE, payload: { value } })
  }

  const setQuery = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_QUERY, payload: { value } })
  }

  const setMinPrice = (value: number) => {
    dispatch({ type: PropertyFiltersActionType.SET_MIN_PRICE, payload: { value } })
  }

  const setMaxPrice = (value: number) => {
    dispatch({ type: PropertyFiltersActionType.SET_MAX_PRICE, payload: { value } })
  }

  const setBeds = (value: number) => {
    dispatch({ type: PropertyFiltersActionType.SET_BEDS, payload: { value } })
  }

  const setBaths = (value: number) => {
    dispatch({ type: PropertyFiltersActionType.SET_BATHS, payload: { value } })
  }

  const setHalfBaths = (value: number) => {
    dispatch({ type: PropertyFiltersActionType.SET_HALF_BATHS, payload: { value } })
  }

  const setMinSquareFootage = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_MIN_SQUARE_FOOTAGE, payload: { value } })
  }

  const setMaxSquareFootage = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_MAX_SQUARE_FOOTAGE, payload: { value } })
  }

  const setMinLotSize = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_MIN_LOT_SIZE, payload: { value } })
  }

  const setMaxLotSize = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_MAX_LOT_SIZE, payload: { value } })
  }

  const setHeatingCooling = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_HEATING_COOLING, payload: { value } })
  }

  const setMinYearBuilt = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_MIN_YEAR_BUILT, payload: { value } })
  }

  const setMaxYearBuilt = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_MAX_YEAR_BUILT, payload: { value } })
  }

  const setMinYearConverted = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_MIN_YEAR_CONVERTED, payload: { value } })
  }

  const setMaxYearConverted = (value: string) => {
    dispatch({ type: PropertyFiltersActionType.SET_MAX_YEAR_CONVERTED, payload: { value } })
  }

  const setParkingSpaces = (value: number) => {
    dispatch({ type: PropertyFiltersActionType.SET_PARKING_SPACES, payload: { value } })
  }

  const setGarages = (value: number) => {
    dispatch({ type: PropertyFiltersActionType.SET_GARAGES, payload: { value } })
  }

  const setOffStreetParking = (value: number) => {
    dispatch({ type: PropertyFiltersActionType.SET_OFF_STREET_PARKING, payload: { value } })
  }

  return (
    <PropertyFiltersContext.Provider value={{
      propertyFiltersState,
      getFiltersQueryString,
      getFiltersForSavedSearch,
      clearFilters,
      setCity,
      setState,
      setQuery,
      setMinPrice,
      setMaxPrice,
      setBeds,
      setBaths,
      setHalfBaths,
      setMinSquareFootage,
      setMaxSquareFootage,
      setMinLotSize,
      setMaxLotSize,
      setHeatingCooling,
      setMinYearBuilt,
      setMaxYearBuilt,
      setMinYearConverted,
      setMaxYearConverted,
      setParkingSpaces,
      setGarages,
      setOffStreetParking
    }}>
      { children}
    </PropertyFiltersContext.Provider >
  );
};

export default PropertyFiltersContext;