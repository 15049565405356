import React, { Fragment, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button, { ButtonType } from '../components/Button';
import UserContext, { UserType } from '../contexts/UserContext';
import calendarIcon from '../assets/images/icon-calendar-big.svg';
import notificationsIcon from '../assets/images/icon-notifications.svg';
import profileIcon from '../assets/images/icon-profile.svg';

interface HeaderProps {
  setIsShowingLoginModal: (value: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({setIsShowingLoginModal}) => {
  const { userState, changeUserType, logOut } = useContext(UserContext);
  const history = useHistory();

  // HANDLERS

  const handleSurvey = () => {
    history.push('/survey');
  }

  const handleLogout = () => {
    logOut();
    history.push('/');
  }

  const handleChangeToSaleAgent = () => {
    changeUserType(UserType.SALE_AGENT)
  }

  const handleShowSaleAgent = () => {
    //
  }

  const handleShowListingAgent = () => {
    //
  }

  // LINKS

  const goToRegister = () => {
    history.push('/register');
  }

  const goToPricing = () => {
    history.push('/pricing');
  }

  const goToAccountDetails = () => {
    history.push('/account-details');
  }

  const goToAccountSettings = () => {
    history.push('/account-settings');
  }

  const goToNotifications = () => {
    history.push('/notifications');
  }

  // RENDERS

  const renderAgentButton = () => {
    if (userState.userType === UserType.BUYER) {
      return (
        <div className="header__agent-button">
          <Button type={ButtonType.HEADER} text="AGENT" clickHandler={handleChangeToSaleAgent} />
        </div>
      );
    }
  }

  const renderNavPrimary = () => {
    return (
      <div className="header__nav--primary">
        <p className="header__nav--primary__button" onClick={handleSurvey}>Take Survey</p>
      </div>
    );
  }

  const renderNavSecondary = () => {
    return (
      <div className="header__nav--secondary">
        <Link to="/" className="header__logo">Navihome</Link>
      </div>
    );
  }
  
  // const renderBranding = () => {
  //   return (
  //     <div className="header__branding">
  //       <div className="header__branding__container">
  //         <p>John Doe</p>
  //         <p>DOE Real Estate</p>
  //         <p className="header__branding__email">johndoe@doerealestate.com</p>
  //         <p className="header__branding__phone">+1 206 123 4567</p>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <header className="header">
      <div className="header__container">
        {renderNavSecondary()}
        {renderNavPrimary()}
      </div>
      {/* {renderBranding()} */}
    </header>
  );
};

export default Header;