import React, { useReducer } from 'react';
import { logInBuyerService, logInSaleAgentService, registerBuyerService, registerSaleAgentService } from '../services/NavihomeService';

enum UserActionType {
  SAVE_TO_LOCAL_STORAGE = 'SAVE_TO_LOCAL_STORAGE',
  LOAD_FROM_LOCAL_STORAGE = 'LOAD_FROM_LOCAL_STORAGE',
  CHANGE_USER_TYPE = 'CHANGE_USER_TYPE',
  IS_LOGGING_IN = 'IS_LOGGING_IN',
  LOG_IN_BUYER_SUCCESS = 'LOG_IN_BUYER_SUCCESS',
  LOG_IN_SALE_AGENT_SUCCESS = 'LOG_IN_SALE_AGENT_SUCCESS',
  SAVE_LOGIN = 'SALE_LOGIN',
  LOG_IN_ERROR = 'LOG_IN_ERROR',
  LOG_OUT = 'LOG_OUT',
  IS_REGISTERING = 'IS_REGISTERING',
  REGISTER_BUYER_SUCCESS = 'REGISTER_BUYER_SUCCESS',
  REGISTER_SALE_AGENT_SUCCESS = 'REGISTER_SALE_AGENT_SUCCESS',
  REGISTER_ERROR = 'REGISTER_ERROR',
}

export enum UserType {
  BUYER = 'BUYER',
  SALE_AGENT = 'SALE_AGENT',
}

type UserAction =
  | { type: UserActionType.SAVE_TO_LOCAL_STORAGE }
  | { type: UserActionType.LOAD_FROM_LOCAL_STORAGE, payload: { state: UserState} }
  | { type: UserActionType.CHANGE_USER_TYPE, payload: { userType: UserType } }
  | { type: UserActionType.CHANGE_USER_TYPE, payload: { userType: UserType } }
  | { type: UserActionType.IS_LOGGING_IN }
  | { type: UserActionType.LOG_IN_BUYER_SUCCESS, payload: { buyerId: number, token: string, name: string } }
  | { type: UserActionType.LOG_IN_SALE_AGENT_SUCCESS, payload: { saleAgentId: number, token: string, name: string, company: string, mlsId: string } }
  | { type: UserActionType.SAVE_LOGIN, payload: { email: string, password: string }}
  | { type: UserActionType.LOG_IN_ERROR, payload: { error: string } }
  | { type: UserActionType.LOG_OUT }
  | { type: UserActionType.IS_REGISTERING}
  | { type: UserActionType.REGISTER_BUYER_SUCCESS, payload: { buyerId: number, token: string, name: string } }
  | { type: UserActionType.REGISTER_SALE_AGENT_SUCCESS, payload: { saleAgentId: number, token: string, name: string, company: string, mlsId: string } }
  | { type: UserActionType.REGISTER_ERROR, payload: { error: string } }

export interface UserState {
  userType: UserType,
  login: {
    email: string,
    password: string
  }
  buyer: {
    buyerId: number,
    token: string,
    name: string,
  } | null,
  saleAgent: {
    saleAgentId: number,
    token: string,
    name: string,
    company: string,
    mlsId: string
  } | null,
  isLoggingIn: boolean,
  isLoggedIn: boolean,
  isRegistering: boolean,
  loginError: string | null,
  registerError: string | null
}

type UserContextType = {
  userState: UserState;
  restoreUserContext: () => void;
  changeUserType: (usertType: UserType) => void;
  logIn: (email: string, password: string) => Promise<boolean>;
  logOut: () => void;
  registerBuyer: (email: string, password: string, phone: string, name: string ) => Promise<boolean>;
  registerSaleAgent: (email: string, password: string, phone: string, name: string, mlsId: string, company: string) => Promise<boolean>;
}

const initialState: UserState = {
  userType: UserType.BUYER,
  login: null,
  buyer: null,
  saleAgent: null,
  isLoggedIn: false,
  isLoggingIn: false,
  isRegistering: false,
  loginError: null,
  registerError: null
}

const initialStateBuyer: UserState = {
  ...initialState,
  userType: UserType.BUYER
}

const initialStateSaleAgent: UserState = {
  ...initialState,
  userType: UserType.SALE_AGENT
} 

const UserContext = React.createContext<UserContextType | undefined>(undefined);

const reducer = (state: UserState, action: UserAction): UserState => {
  const storage = window.localStorage;
  switch (action.type) {
    case UserActionType.SAVE_TO_LOCAL_STORAGE:
      storage.setItem('userContext', JSON.stringify(state));
      return { ...state };
    case UserActionType.LOAD_FROM_LOCAL_STORAGE:
      return { ...action.payload.state}
    case UserActionType.CHANGE_USER_TYPE:
      return { ...initialState, userType: action.payload.userType };

    case UserActionType.IS_LOGGING_IN:
      return { ...initialState, userType: state.userType, isLoggingIn: true };

    case UserActionType.LOG_IN_BUYER_SUCCESS:
      return { ...initialStateBuyer, buyer: action.payload, isLoggedIn: true, login: state.login };

    case UserActionType.LOG_IN_SALE_AGENT_SUCCESS:
      return { ...initialStateSaleAgent, saleAgent: action.payload, isLoggedIn: true, login: state.login };

    case UserActionType.SAVE_LOGIN:
      return { ...state, login: { email: action.payload.email, password: action.payload.password } };

    case UserActionType.LOG_IN_ERROR:
      return { ...initialState, userType: state.userType, loginError: action.payload.error };

    case UserActionType.LOG_OUT:
      return { ...initialState };

    case UserActionType.IS_REGISTERING:
      return { ...initialState, userType: state.userType, isRegistering: true, login: state.login };

    case UserActionType.REGISTER_BUYER_SUCCESS:
      return { ...initialStateBuyer, buyer: action.payload, isLoggedIn: true, login: state.login };

    case UserActionType.REGISTER_SALE_AGENT_SUCCESS:
      return { ...initialStateSaleAgent, saleAgent: action.payload, isLoggedIn: true, login: state.login };

    case UserActionType.REGISTER_ERROR:
      return { ...initialState, userType: state.userType, registerError: action.payload.error };

    default:
      return state;
  }
}

export const UserProvider: React.FC = ({ children }) => {
  const [userState, dispatch] = useReducer(reducer, initialState);

  const restoreUserContext = async () => {
    const storage = window.localStorage;
    const data = storage.getItem('userContext');
    if (data) {
      var parsedData: UserState = JSON.parse(data);
      if (parsedData.isLoggedIn) {
        switch(parsedData.userType) {
          case UserType.BUYER:
            const buyerData = await logInBuyerService(parsedData.login.email, parsedData.login.password);
            parsedData.buyer.token = buyerData.token
            break;
          case UserType.SALE_AGENT:
            const saleAgentData = await logInSaleAgentService(parsedData.login.email, parsedData.login.password);
            parsedData.saleAgent.token = saleAgentData.token;
            break;
          default:
            break;
        }
      }
      dispatch({type: UserActionType.LOAD_FROM_LOCAL_STORAGE, payload: { state: parsedData }});
      dispatch({type: UserActionType.SAVE_TO_LOCAL_STORAGE });
    }
  };

  const changeUserType = (userType: UserType) => {
    dispatch({ type: UserActionType.CHANGE_USER_TYPE, payload: { userType } })
  }

  const logIn = async (email: string, password: string): Promise<boolean> => {
    dispatch({ type: UserActionType.IS_LOGGING_IN });

    if (userState.userType === UserType.BUYER) {
      try {
        const { buyerId, token, name } = await logInBuyerService(email, password);
        dispatch({type: UserActionType.LOG_IN_BUYER_SUCCESS, payload: { buyerId, token, name }});
        dispatch({type: UserActionType.SAVE_LOGIN, payload: { email, password }});
        dispatch({type: UserActionType.SAVE_TO_LOCAL_STORAGE });
        return true;
      } catch(error) {
        if (error instanceof Error) {
          dispatch({type: UserActionType.LOG_IN_ERROR, payload: { error: error.message}});
        }
        dispatch({type: UserActionType.LOG_IN_ERROR, payload: { error: "Unknown Error Occured."}});
        return false;
      }
    }

    if (userState.userType === UserType.SALE_AGENT) {
      try {
        const { saleAgentId, token, name, company, mlsId } = await logInSaleAgentService(email, password);
        dispatch({type: UserActionType.LOG_IN_SALE_AGENT_SUCCESS, payload: { saleAgentId, token, name, company, mlsId }});
        dispatch({type: UserActionType.SAVE_LOGIN, payload: { email, password }});
        dispatch({type: UserActionType.SAVE_TO_LOCAL_STORAGE });
        return true;
      } catch(error) {
        if (error instanceof Error) {
          dispatch({type: UserActionType.LOG_IN_ERROR, payload: { error: error.message}});
        }
        dispatch({type: UserActionType.LOG_IN_ERROR, payload: { error: "Unknown Error Occured."}});
        return false;
      }
    }
  };

  const logOut = () => {
    dispatch({ type: UserActionType.LOG_OUT });
    dispatch({ type: UserActionType.SAVE_TO_LOCAL_STORAGE});
  };

  const registerBuyer = async (email: string, password: string, phoneNumber: string, name: string): Promise<boolean> => {
    dispatch({ type: UserActionType.IS_REGISTERING });

    try {
      const { buyerId, token } = await registerBuyerService(email, password, phoneNumber, name);
      dispatch({type: UserActionType.REGISTER_BUYER_SUCCESS, payload: { buyerId, token, name}});
      dispatch({type: UserActionType.SAVE_LOGIN, payload: { email, password }});
      dispatch({type: UserActionType.SAVE_TO_LOCAL_STORAGE });
      return true;
    } catch(error) {
      if (error instanceof Error) {
        dispatch({type: UserActionType.REGISTER_ERROR, payload: { error: error.message}});
      }
      dispatch({type: UserActionType.REGISTER_ERROR, payload: { error: "Unknown Error Occured."}});
      return false;
    }
  }

  const registerSaleAgent = async (email: string, password: string, phone: string, name: string, mlsId: string, company: string): Promise<boolean> => {
    dispatch({ type: UserActionType.IS_REGISTERING })

    try {
      const { saleAgentId, token } = await registerSaleAgentService(email, password, phone, name, company, mlsId);
      dispatch({type: UserActionType.REGISTER_SALE_AGENT_SUCCESS, payload: { saleAgentId, token, name, company, mlsId}});
      dispatch({ type: UserActionType.SAVE_LOGIN, payload: { email, password }});
      dispatch({type: UserActionType.SAVE_TO_LOCAL_STORAGE });
      return true;
    } catch(error) {
      if (error instanceof Error) {
        dispatch({type: UserActionType.REGISTER_ERROR, payload: { error: error.message}});
      }
      dispatch({type: UserActionType.REGISTER_ERROR, payload: { error: "Unknown Error Occured."}});
      return false;
    }
  }

  return (
    <UserContext.Provider value={{ userState, restoreUserContext, changeUserType, logIn, logOut, registerBuyer, registerSaleAgent }}>
      { children}
    </UserContext.Provider >
  );
};

export default UserContext;