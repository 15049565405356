import { useState, useContext, useRef } from 'react';
import UserContext, { UserType } from '../contexts/UserContext';
import buttonClose from '../assets/images/button-close.svg';
import TextField, { TextFieldType, TextFieldIconType } from '../components/TextField';
import Button, { ButtonType } from '../components/Button';
import Checkbox, { CheckboxType } from '../components/Checkbox';
import { useHistory } from 'react-router';
import { sendForgotPasswordForBuyerService, sendForgotPasswordForSaleAgentService } from '../services/NavihomeService';

interface LogInModalProps {
  isShowing: boolean;
  closeAction: () => void;
}

const LogInModal: React.FC<LogInModalProps> = ({ isShowing, closeAction }) => {
  const { userState, logIn } = useContext(UserContext);
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleLogIn = async () => {
    const success = await logIn(email, password);
    if (success) {
      closeAction();
      history.push("/");
    }
  }

  const handleForgotPassword = async () => {
    if (email === '') {
      alert("Please enter your email.");
      return;
    }

    if (userState.userType === UserType.BUYER) {
      await sendForgotPasswordForBuyerService(email);
    }

    if (userState.userType === UserType.SALE_AGENT) {
      await sendForgotPasswordForSaleAgentService(email);
    }
    
    closeAction();
    alert("A temporary password will be sent to your email shortly!");
  }

  const goToRegister = () => {
    closeAction();
    history.push("/register");
  }

  const modalClassName = isShowing ? "login-modal u-visible" : "login-modal u-hidden";
  const cardClassName = isShowing ? "login-modal__card animation__pop-in" : "login-modal__card animation__pop-out"

  return (
    <div className={modalClassName}>
      <div className={cardClassName}>
        <div className="login-modal__row">
          <h3>Welcome Back</h3>
          <img className="login-modal__close-button" src={buttonClose} onClick={closeAction} alt="Close button" />
        </div>
        <div className="login-modal__column">
          <p className="login-modal__error-message">{userState.loginError}</p>
          <TextField
            value={email}
            setValue={setEmail}
            placeholder="janedoe@hotmail.com"
            label="Email"
            type={TextFieldType.ICON_AND_LABEL}
            icon={TextFieldIconType.EMAIL}
            isRequired={true}
            width={38.4}
            className="login-modal__email-field" />
          <TextField
            value={password}
            setValue={setPassword}
            placeholder="••••••••"
            label="Password"
            type={TextFieldType.PASSWORD}
            icon={TextFieldIconType.PASSWORD}
            isRequired={true}
            width={38.4}
            className="login-modal__password-field" />
          <Checkbox
            type={CheckboxType.SMALL}
            value={rememberMe}
            setValue={setRememberMe}
            text="Remember me" />
          <Button
            type={ButtonType.PRIMARY}
            text="LOG IN"
            clickHandler={handleLogIn}
            width={18.5}
            className="login-modal__button" />
          <p className="login-modal__forgot-password" onClick={handleForgotPassword}>Forgot Password?</p>
          <p>Don't have an account? <span className="login-modal__register-now" onClick={goToRegister}>Register now</span></p>
        </div>
      </div>
    </div>
  )
};

export default LogInModal;