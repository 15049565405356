import React from 'react';
import iconUser from '../assets/images/icon-user.svg';
import iconEmail from '../assets/images/icon-email.svg';
import iconPhone from '../assets/images/icon-phone.svg';
import iconPassword from '../assets/images/icon-password.svg';
import iconCompany from '../assets/images/icon-company.svg';
import iconHouse from '../assets/images/icon-house.svg';
import iconLocation from '../assets/images/icon-location.svg';
import iconNumber from '../assets/images/icon-number.svg';
import iconCalendar from '../assets/images/icon-calendar.svg';

export enum TextFieldType {
  DEFAULT = 'TEXT_FIELD_DEFAULT',
  SMALL = 'TEXT_FIELD_SMALL',
  BIG = 'TEXT_FIELD_BIG',
  ICON_AND_LABEL = 'TEXT_FIELD_WITH_ICON_AND_LABEL',
  PASSWORD = 'TEXT_FIELD_PASSWORD',
}

export enum TextFieldIconType {
  NONE = 'ICON_NONE',
  USER = 'ICON_USER',
  EMAIL = 'ICON_EMAIL',
  PHONE = 'ICON_PHONE',
  PASSWORD = 'ICON_PASSWORD',
  COMPANY = 'ICON_COMPANY',
  HOUSE = 'ICON_HOUSE',
  LOCATION = 'ICON_LOCATION',
  NUMBER = 'ICON_NUMBER',
  CALENDAR = 'ICON_CALENDAR'
}

interface TextFieldProps {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  type: TextFieldType;
  label?: string;
  icon?: TextFieldIconType;
  isRequired: boolean;
  width?: number;
  className?: string;
}

const TextField: React.FC<TextFieldProps> = ({ value, setValue, placeholder, type, label, icon, isRequired, width, className }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
  }

  const renderLabel = () => {
    if (type === TextFieldType.ICON_AND_LABEL || type === TextFieldType.BIG || type === TextFieldType.PASSWORD) {
      return (
        <label className="text-field__label">{isRequired ? label + '*' : label}</label>
      );
    }
  }

  const renderIcon = () => {
    if (type === TextFieldType.ICON_AND_LABEL || type === TextFieldType.BIG || type === TextFieldType.PASSWORD) {
      let iconSrc = '';
      switch (icon) {
        case TextFieldIconType.USER: {
          iconSrc = iconUser;
          break;
        }
        case TextFieldIconType.EMAIL: {
          iconSrc = iconEmail;
          break;
        }
        case TextFieldIconType.PHONE: {
          iconSrc = iconPhone;
          break;
        }
        case TextFieldIconType.PASSWORD: {
          iconSrc = iconPassword;
          break;
        }
        case TextFieldIconType.COMPANY: {
          iconSrc = iconCompany;
          break;
        }
        case TextFieldIconType.HOUSE: {
          iconSrc = iconHouse;
          break;
        }
        case TextFieldIconType.LOCATION: {
          iconSrc = iconLocation;
          break;
        }
        case TextFieldIconType.NUMBER: {
          iconSrc = iconNumber;
          break;
        }
        case TextFieldIconType.CALENDAR: {
          iconSrc = iconCalendar;
          break;
        }
        default: {
          break;
        }
      }
      return (
        <img className="text-field__icon" src={iconSrc} alt="TextField Icon" />
      );
    }
  }

  const renderInput = () => {
    let classNames = 'text-field__input';
    switch (type) {
      case TextFieldType.SMALL: {
        classNames += ' text-field__input--small';
        break;
      }
      case TextFieldType.ICON_AND_LABEL: {
        classNames += ' text-field__input--with-icon';
        break;
      }
      case TextFieldType.PASSWORD: {
        classNames += ' text-field__input--with-icon';
        break;
      }
      default: {
        break;
      }
    }

    const height = type === TextFieldType.BIG ? "19rem" : "4.8rem"

    return (
      type === TextFieldType.BIG ?
        <textarea
          value={value}
          onChange={handleTextAreaChange}
          placeholder={placeholder}
          className={classNames}
          required={isRequired}
          style={{ width: width === undefined ? "32.9rem" : `${width}rem`, height: height }} />
        :
        <input
          type={type === TextFieldType.PASSWORD ? "password" : "text"}
          value={value}
          onChange={handleInputChange}
          placeholder={placeholder}
          className={classNames}
          required={isRequired}
          style={{ width: width === undefined ? "32.9rem" : `${width}rem` }} />
    );
  }

  return (
    <div className={className === undefined ? "text-field" : `text-field ${className}`}>
      {renderLabel()}
      {renderIcon()}
      {renderInput()}
    </div>
  );
};

export default TextField;