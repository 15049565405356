import { useState } from "react"
import Button, { ButtonType } from "../components/Button";
import TextField, { TextFieldIconType, TextFieldType } from "../components/TextField";

const Footer: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [cityState, setCityState] = useState('');

  const sendLead = () => {

  }

  return (
    <div></div>
  );
};

export default Footer;