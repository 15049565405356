import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";

const SurveyPage: React.FC = () => {

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "21139910",
    formId: "0c308ae1-d0d5-4e52-802a-4ab5ef552e84",
    target: '#hubspot-survey-form'
  });

  return (
    <div className="survey-page">
      <div className="survey-page-container">
          <div className="hb-form">
            <div id="hubspot-survey-form"></div>
          </div>
      </div>
    </div>
  );
};

export default SurveyPage;