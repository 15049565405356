import React, { useReducer } from 'react';

enum SaleAgentActionType {
  SAVE_TO_LOCAL_STORAGE = 'SAVE_TO_LOCAL_STORAGE',
  LOAD_FROM_LOCAL_STORAGE = 'LOAD_FROM_LOCAL_STORAGE',
  SELECT_BUYER = 'SELECT_BUYER',
  CLEAR_BUYER = 'CLEAR_BUYER'
}


type SaleAgentAction =
  | { type: SaleAgentActionType.SAVE_TO_LOCAL_STORAGE }
  | { type: SaleAgentActionType.LOAD_FROM_LOCAL_STORAGE }
  | { type: SaleAgentActionType.SELECT_BUYER, payload: { buyerId: number, buyerName: string } }
  | { type: SaleAgentActionType.CLEAR_BUYER }

export interface SaleAgentState {
  buyerId: number | null,
  buyerName: string | null
}

type SaleAgentContextType = {
  saleAgentState: SaleAgentState;
  restoreSaleAgentContext: () => void;
  selectBuyer: (buyerId: number, buyerName: string) => void;
  clearBuyer: () => void;
}

const initialState: SaleAgentState = {
  buyerId: null,
  buyerName: null
}

const SaleAgentContext = React.createContext<SaleAgentContextType | undefined>(undefined);

const reducer = (state: SaleAgentState, action: SaleAgentAction): SaleAgentState => {
  const storage = window.localStorage;
  switch (action.type) {
    case SaleAgentActionType.SAVE_TO_LOCAL_STORAGE:
      storage.setItem('saleAgentContext', JSON.stringify(state));
      return { ...state };
    case SaleAgentActionType.LOAD_FROM_LOCAL_STORAGE:
      const data = storage.getItem('saleAgentContext');
      if (data) {
        const parsedData = JSON.parse(data);
        return { ...parsedData };
      }
      return { ...state};
    case SaleAgentActionType.SELECT_BUYER:
      return { ...state, buyerId: action.payload.buyerId, buyerName: action.payload.buyerName }
    case SaleAgentActionType.CLEAR_BUYER:
      return { ...initialState };
    default:
      return state;
  }
}

export const SaleAgentProvider: React.FC = ({ children }) => {
  const [saleAgentState, dispatch] = useReducer(reducer, initialState);

  const restoreSaleAgentContext = () => {
    dispatch({ type: SaleAgentActionType.LOAD_FROM_LOCAL_STORAGE });
  }

  const selectBuyer = (buyerId: number, buyerName: string) => {
    dispatch({ type: SaleAgentActionType.SELECT_BUYER, payload: { buyerId, buyerName }});
    dispatch({ type: SaleAgentActionType.SAVE_TO_LOCAL_STORAGE });
  }

  const clearBuyer = () => {
    dispatch({ type: SaleAgentActionType.CLEAR_BUYER });
    dispatch({ type: SaleAgentActionType.SAVE_TO_LOCAL_STORAGE });
  }

  return (
    <SaleAgentContext.Provider value={{ saleAgentState, restoreSaleAgentContext, selectBuyer, clearBuyer }}>
      { children}
    </SaleAgentContext.Provider >
  );
};

export default SaleAgentContext;