import { Route } from 'react-router-dom';
import LandingSaleAgentPage from '../pages/LandingSaleAgentPage';
import SurveyPage from '../pages/SurveyPage';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';

const PageContent: React.FC = () => {

  return (
    <HubspotProvider>
      <main className="page-content">
        <Route path="/" exact component={LandingSaleAgentPage} />
        <Route path="/survey" exact component={SurveyPage} />
      </main>
    </HubspotProvider>
  );
};

export default PageContent;