import { useContext, useEffect, useState } from 'react';
import { BrowserRouter} from 'react-router-dom';
import { AppContextProvider } from './contexts/AppContextProvider';
import Header from './layout/Header';
import PageContent from './layout/PageContent';
import Footer from './layout/Footer';
import LogInModal from './modals/LogInModal';
import UserContext from './contexts/UserContext';

const App: React.FC = () => {
  const [isShowingLoginModal, setIsShowingLoginModal] = useState(false);

  const handleCloseLoginModal = () => {
    setIsShowingLoginModal(false);
  }
  
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Header setIsShowingLoginModal={setIsShowingLoginModal} />
        <PageContent />
        <Footer />
        {isShowingLoginModal && <LogInModal isShowing={isShowingLoginModal} closeAction={handleCloseLoginModal}/>}
      </BrowserRouter>
    </AppContextProvider>
  );
};

export default App;