// import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import triangleArrow from '../assets/images/triangle-arrow.svg';
import agentFeature1 from '../assets/images/landing-agent-feature-1.svg';
import agentFeature2 from '../assets/images/landing-agent-feature-2.svg';
import agentFeature3 from '../assets/images/landing-agent-feature-3.svg';
import agentFeature4 from '../assets/images/landing-agent-feature-4.svg';
import agentFeature5 from '../assets/images/landing-agent-feature-5.svg';
import agentFeature6 from '../assets/images/landing-agent-feature-6.svg';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

// import UserContext from '../contexts/UserContext';

const LandingSaleAgentPage: React.FC = () => {
  const history = useHistory();
  // const { userState } = useContext(UserContext);

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "21139910",
    formId: "9cb0fa95-6e1f-4bf0-b661-832e6a44698c",
    target: '#hubspot-contact-form'
  });

  return (
    <div className="landing-sale-agent-page">
      <div className="lading-sale-agent-page__offer">
        <div className="lading-sale-agent-page__offer__container">
          <p>Coming Soon</p>
        </div>
      </div>
      <div className="landing-sale-agent-page__hero-section">
        <div className="landing-sale-agent-page__hero-section__content">
          <h4>Navihome. When your superpower is selling, not scheduling.</h4>
          <h1>Schedule Showings.</h1>
          <h2>Easily. Effortlessly. Immediately</h2>
          <p>Eliminate the back-and-forth scheduling saga of calls, texts, and emails. Create quick and seamless experiences for home showings with just a few clicks and keystrokes!</p>
          <p>Simplify scheduling and routing and free up your time.</p>
          <p><span className="f-subtitle-1">Navihome: The app, designed with the agent in mind.</span></p>
          <p>It is like having a scheduling assistant 24/7.</p>
        </div>
        <img className="landing-sale-agent-page__hero-section__image" src={agentFeature1} alt="Agent Feature 1"/>
      </div>
      <div className="landing-sale-agent-page__steps-section">
        <div className="landing-sale-agent-page__steps-section__item">
          <p className="landing-sale-agent-page__steps-section__number">1</p>
          <img className="landing-sale-agent-page__steps-section__arrow" src={triangleArrow} alt="Arrow"/>
          <div className="landing-sale-agent-page__steps-section__item__content">
            <p>Provide your availability</p>
            <p>Choose your scheduling parameters.</p>
            <p>Offer your buyers and listing agents easy scheduling.</p>
          </div>
        </div>
        <div className="landing-sale-agent-page__steps-section__item">
          <p className="landing-sale-agent-page__steps-section__number">2</p>
          <img className="landing-sale-agent-page__steps-section__arrow" src={triangleArrow} alt="Arrow"/>
          <div className="landing-sale-agent-page__steps-section__item__content">
            <p>Request showings and optimize your route</p>
            <p>Navihome provides the optimal routing solution.</p>
          </div>
        </div>
        <div className="landing-sale-agent-page__steps-section__item">
          <p className="landing-sale-agent-page__steps-section__number">3</p>
          <img className="landing-sale-agent-page__steps-section__arrow" src={triangleArrow} alt="Arrow"/>
          <div className="landing-sale-agent-page__steps-section__item__content">
            <p>Book with one click</p>
            <p>Click one button to request showings for all properties in the route.</p>
          </div>
        </div>
      </div>
      <div className="landing-sale-agent-page__highlight-text-block">
        <p>Never lose a client again</p>
        <p>Leverage the power of our unparalleled technology. Avoid scheduling headaches. Create professional, enjoyable experiences for your buyers.</p>
      </div>
      <div className="landing-sale-agent-page__you-get">
        <h3>You get:</h3>
        <div className="landing-sale-agent-page__you-get__item">
          <img src={agentFeature2} alt="Feature"/>
          <h5>Calendar sync and viewing schedule of all stakeholders</h5>
          <p>• &nbsp;Display optimal timeframes for property view appointments for both listing agents accompanied &amp; unaccompanied showings.</p>
        </div>
        <div className="landing-sale-agent-page__you-get__item">
          <img src={agentFeature3} alt="Feature"/>
          <h5>Engage your GPS to optimise your routing</h5>
          <p>• &nbsp;Location-awareness to book optimal routes when one or multiple properties are involved.</p>
        </div>
        <div className="landing-sale-agent-page__you-get__item">
          <img src={agentFeature4} alt="Feature"/>
          <h5>Page branding (optional)</h5>
          <p>• &nbsp;Add your name, logo, profile photo, and contact details to create a personalized experience.</p>
        </div>
      </div>
      
      <div className="landing-sale-agent-page__features">
        <div className="landing-sale-agent-page__features__item__text">
          <h4>Increase Appointment Reliability</h4>
          <p>Keep your buyers on track with automated reminders, confirmations, links to Google calendar, and simple rescheduling.</p>
        </div>
        <img src={agentFeature5} alt="Feature"/>
        <img src={agentFeature6} alt="Feature"/>
        <div className="landing-sale-agent-page__features__item__text">
          <h4>Invite Buyers to Browse</h4>
          <p>Deepen your understanding of buyer preferences. See what they like in the portal, view which open houses they attend.</p>
        </div>
      </div>
      <div className="landing-sale-agent-page__created-by">
        <p>Created by real estate agents for real estate agents</p>
      </div>
      <div className="landing-sale-agent-page__ending-features">
        <div className="landing-sale-agent-page__ending-features__text">
          <h3>We have got your back!</h3>
          <h4>Show more, Schedule less</h4>
          <p>Navihome is the brainchild of successful real estate agents who understand scheduling challenges. It is time to put technology to work for us:</p>
          <ul>
            <li>Minimize scheduling time</li>
            <li>Increase showings</li>
            <li>Offer professional scheduling experiences and reduce stress for buyers</li>
            <li>Reduce missed appointments</li>
            <li>Regain more time to focus on what is important: your clients</li>
          </ul>
        </div>
      </div>
      <footer className='footer'>
        <div className='footer__container'>
          <h3>Sign up here to be the first to try our app for free!</h3>
        </div>
      </footer>
      <div className='hb-form'>
        <div id="hubspot-contact-form"></div>
      </div>
    </div>
  );
};

export default LandingSaleAgentPage;