export enum ButtonType {
  PRIMARY = 'BUTTON_PRIMARY',
  PRIMARY_NARROW = 'BUTTON_PRIMARY_NARROW',
  SECONDARY = 'BUTTON_SECONDARY',
  WHITE = 'BUTTON_WHITE',
  WHITE_NARROW = 'BUTTON_WHITE_NARROW',
  WHITE_SMALL = 'BUTTON_WHITE_SMALL',
  HEADER = 'BUTTON_HEADER',
}

interface ButtonProps {
  type: ButtonType;
  text: string;
  clickHandler: () => void;
  className?: string;
  width?: number;
}

const Button: React.FC<ButtonProps> = ({ type, text, clickHandler, className, width }) => {
  let mainClassName = '';

  switch (type) {
    case ButtonType.PRIMARY:
      mainClassName = 'button--primary'
      break;
    case ButtonType.PRIMARY_NARROW:
      mainClassName = 'button--primary-narrow'
      break;
    case ButtonType.SECONDARY:
      mainClassName = 'button--secondary'
      break;
    case ButtonType.WHITE:
      mainClassName = 'button--white'
      break;
    case ButtonType.WHITE_NARROW:
      mainClassName = 'button--white-narrow'
      break;
    case ButtonType.WHITE_SMALL:
      mainClassName = 'button--white-small'
      break;
    case ButtonType.HEADER:
      mainClassName = 'button--header'
      break;
    default:
      mainClassName = ''
      break;
  }

  return (
    <div 
      className={className === undefined ? `button ${mainClassName}` : `button ${mainClassName} ${className}`} 
      onClick={clickHandler} 
      style={width !== undefined ? {width: `${width}rem`} : {}}>
      <p>{text}</p>
    </div>
  );
};

export default Button;